import { LoadingButton } from '@mui/lab'
import {
  Button,
  LinearProgress,
  Paper,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
  linearProgressClasses,
  styled
} from '@mui/material'
import { Stack } from '@mui/system'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'
import PluggerLogoTransp from '../../components/PluggerLogoTransp'
import useAuth from '../../hooks/useAuth'
import initAnimation from './animation'
import './index.css'

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  width: '100%',
  backgroundColor: '#101418',
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#101418'
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: '10px',
    backgroundColor: '#39e991'
  }
}))

const alternativePrimaryColorTheme = createTheme({
  palette: {
    primary: {
      main: '#39e991'
    }
  }
})

const slidesCount = 4

export const SignUpPage = () => {
  const [loading, setLoading] = useState(false)
  const auth = useAuth()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const [swiper, setSwiper] = useState<SwiperClass>()
  const [slide, setSlide] = useState(1)

  useEffect(() => {
    initAnimation()
  }, [])

  useEffect(() => {
    google.accounts.id.initialize({
      client_id: '355793896683-uqn4thce4p36d2ia6ib7apu7loh63u5b.apps.googleusercontent.com',
      ux_mode: 'popup',
      cancel_on_tap_outside: false,
      context: 'signup',
      callback: (response) => {
        console.log(response)
        // setLoading(true)

        // if (response.credential) {
        //   authService
        //     .signUpGoogle(response.credential)
        //     .then(async ({ data }) => {
        //       const appToken = await auth.signInGoogle(response.credential)
        //       const searchParams = new URLSearchParams()
        //       searchParams.set('token', appToken)
        //       searchParams.set('email', data.email)
        //       searchParams.set('userId', data.id)
        //       searchParams.set('firstName', data.firstName)
        //       searchParams.set('lastName', data.lastName)
        //       navigate('/confirm?' + searchParams.toString())
        //     })
        //     .catch(async (err: any) => {
        //       if (err.response?.status === 409) {
        //         await auth.signInGoogle(response.credential)
        //         navigate('/new-account')
        //       } else {
        //         const message = err.response?.data?.message || err.message
        //         enqueueSnackbar(message, { variant: 'error' })
        //         setLoading(false)
        //       }
        //     })
        //}
      }
    })
    google.accounts.id.renderButton(document.getElementById('google-btn')!, {
      type: 'standard',
      size: 'large',
      width: '480',
      text: 'continue_with',
      theme: 'outline'
    })
  }, [auth, enqueueSnackbar, navigate, t])

  const slideNext = () => {
    setSlide((prev) => prev + 1)
    swiper?.slideNext()
  }
  const slidePrev = () => {
    setSlide((prev) => prev - 1)
    swiper?.slidePrev()
  }

  return (
    <ThemeProvider theme={alternativePrimaryColorTheme}>
      <div id="large-header" className="large-header">
        <canvas style={{ display: 'block' }} id="demo-canvas"></canvas>
        <Paper
          elevation={4}
          sx={{
            maxWidth: 480,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate3d(-50%,-50%,0)',
            backgroundColor: '#101418',
            display: 'flex',
            borderRadius: '10px'
          }}
        >
          <Stack spacing={4} flexGrow={1} alignItems="center" padding={4} color="#ffffff">
            <PluggerLogoTransp style={{ marginBottom: 12 }} />
            <BorderLinearProgress variant="determinate" value={(slide / slidesCount) * 100} />
            <Swiper style={{ maxWidth: 400 }} onSwiper={setSwiper}>
              <SwiperSlide>
                <Stack spacing={6}>
                  <Typography color="#ffffff" fontSize="1.25rem">
                    Agora precisamos identifica-lo. É rápido, fácil e gratuito.
                  </Typography>
                  <Stack spacing={2}>
                    <div id="google-btn"></div>
                    <span style={{ color: '#fff', textAlign: 'center' }}> Ou </span>
                    <TextField
                      inputProps={{
                        style: {
                          color: '#ffffff'
                        }
                      }}
                      fullWidth
                      label="Email"
                      variant="outlined"
                      focused
                    />
                    <TextField
                      inputProps={{
                        style: {
                          color: '#ffffff'
                        }
                      }}
                      type="password"
                      fullWidth
                      label="Senha"
                      variant="outlined"
                      focused
                    />
                    <LoadingButton onClick={slideNext} loading={loading}>
                      Criar
                    </LoadingButton>
                  </Stack>
                </Stack>
              </SwiperSlide>
              <SwiperSlide>
                <Stack spacing={2}>
                  <Typography color="#ffffff" fontSize="1.25rem">
                    Quantos functionários trabalham na sua empresa?
                  </Typography>
                  <Button> Somente eu </Button>
                  <Button> 2 a 5</Button>
                  <Button> 10 a 50</Button>
                  <Button> 50 ou mais</Button>
                  <Button onClick={slidePrev}> prev test</Button>
                </Stack>
              </SwiperSlide>
            </Swiper>
          </Stack>
        </Paper>
      </div>
    </ThemeProvider>
  )
}
